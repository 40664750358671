<template>
  <div class="container">
    <b-card v-if="verify_status === 'PENDING'" class="verify-bankid-body mb-5" style="margin: auto; border: 1px solid #e1e1e1;">
      <h3 class="text-center mt-4 mb-4">{{$t('MEMBER.VERIFY_WITH_BANKID')}}</h3>
      <p>Innan er ansökan kan behandlas behöver vi verifiera ditt personummer. Det personnummer som fylldes i måste överrensstämma med det personummer som signeras via BankID.</p>
      <form>

        <NativeBankIDComponent
          v-if="!show_mismatch"
          type="sign"
          intent="verify_samfund"
          @authenticated="verified"
          @failed="failed"
        />

        <b-alert
          :show="show_mismatch"
          variant="danger"
          dismissible
          @dismissed="show_mismatch = false"
          fade
          >{{$t('MEMBER.BANKID_VERIFY_PERSONNR_MISMATCH')}}</b-alert
        >

      </form>
    </b-card>

    <b-card v-else-if="verify_status === 'DONE'">

      <div v-if="create_status === 'PENDING'" class="d-flex justify-content-center mt-3" >
        <b-spinner label="Loading..."></b-spinner>
      </div>

      <b-card v-else-if="create_status === 'CREATED'">
        <b-card :title="$t('PUBLIC.REGISTRATION.MEMBERSHIP_PENDING')" hide-footer class="mb-2 mt-2 ">
          <b-alert variant="success" show
            >{{$t('PUBLIC.REGISTRATION.MEMBERSHIP_PENDING_INFO')}}</b-alert
          >
        </b-card>
      </b-card>

      <b-card v-else-if="create_status === 'ERROR'">
        <b-card :title="$t('PUBLIC.REGISTRATION.MEMBERSHIP_ERROR')" hide-footer class="mb-2 mt-2 ">
          <b-alert variant="danger" show
            >{{$t('PUBLIC.REGISTRATION.MEMBERSHIP_ERROR_MESSAGE')}}</b-alert
          >
        </b-card>
      </b-card>
    </b-card>

    <b-card v-else-if="verify_status === 'RETURNED'">
      <h4>Väntar på svar...</h4>

      <p>Vi inväntar svar från BankID tjänsten.</p>
    </b-card>

    <b-card v-else-if="verify_status === 'ERROR'">
      <h4>Ett fel uppstod</h4>

      <p>BankID signering resulterade i ett fel. Säkerställ att ni registrerade er med samma personnummer som er BankID använder.</p>
    </b-card>

    <div v-for="(item, index) in errors" :key="index" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
      <div class="alert-icon"><i class="flaticon-warning"></i></div>
      <div class="alert-text"><p>ERROR: {{ item }}</p></div>  
    </div>

  </div>
  
</template>

<style lang="scss" scoped>

.container {
  margin: auto;
}

#qr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.verify-bankid-body {
  max-width: 500px;
  width: 100%;
  .login-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
}
</style>

<script>
import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { mapState } from 'vuex';

import axios from 'axios';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { isPlugin } from "@/core/services/messageParent";
import messageParent from '@/core/services/messageParent';
import NativeBankIDComponent from '@/view/components/bankid/NativeBankIDComponent.vue';

export default {
  mixins: [toasts],
  name: 'VerifyMemberBankID',

  components: {
    NativeBankIDComponent
  },

  data() {
    return {
      
      personnr: null,
      post_register_url: null,
      form: null,

      verify_status: 'PENDING',

      create_status: 'PENDING',

      successful_create: false,

      show_mismatch: false,

      errors: []
    };
  },
  /*
  BankId flow for samfund:

  RegisterWizard store its form in the 'form'. We also store away the personnr and post_register_url.

  RegisterWizard will forward to this page using this.$router.push({ name: 'bankid-verify-member' });

  All the form data will be retrieved using getKeyValue. However we can not rely on the form data beyond this point
  as the bankid app will open in a new window.

  We will post
  const res = await axios.post(`/bankid/member/verify?qr=${qr ? 'true' : 'false'}&token=${this.bankid_auth_token}`, { form: form, personnr: personnr, post_register_url: post_register_url });

  axios.post(`/bankid/member/collect/token/personnr`
  will collect the data of the member, and we will also get the registration form data

  when using the open app locally option, we return to this page, but we cant rely on getKeyValue

  
  */
  
  async mounted() {

    this.nonce = this.$route.query.nonce;

    if (this.nonce) {
      this.verify_status = 'RETURNED';

      // if returned from bankid
      await this.fetch_returned_sign();
    }
    else {
      // if first time we enter, store away the form
      this.form = JSON.parse(getKeyValue('form'));
      this.personnr = getKeyValue('personnr');
      this.post_register_url = getKeyValue('post_register_url');

      await this.post_store_form(this.form, this.personnr, this.post_register_url);
    }

  },

  methods: {

    failed() {
      this.show_mismatch = true;
    },

    // store the form temporary, needed for when we return later after opening the bankid app
    async post_store_form(form, personnr, post_register_url) {
      const res = await axios.post(`/form/public/legacy`, {
        form,
        personnr,
        post_register_url 
      });

      if (res.status === 201) {
        return true;
      }

      return false;
    },

    // get the stored form, only used when returning from app
    async get_store_form(personnr) {
      try {
        const res = await axios.get(`/form/public/legacy?personnr=${personnr}`);

        if (res.status === 200) {
          return res.data;
        }

        console.error('unable to get form');
      }
      catch (err) {
        console.error('error getting stored form', err);
      }
      
      return {
        status: 'failed',
        personnr: 'xx'
      }
    },

    /** { status: 'verified', personnr: 'xxx' } */
    async verified(data) {
      try {
        const stored_data = await this.get_store_form(data.personnr);

        if (stored_data.personnr.replace('-','') !== data.personnr.replace('-','')) {
          this.show_mismatch = true;

          return false;
        }

        this.verify_status = 'DONE'; 

        this.form = stored_data.form;
        this.post_register_url = stored_data.post_register_url;
        this.personnr = stored_data.personnr;
        
        await this.register_member(this.form, this.post_register_url);
      }
      catch (err) {
        console.error('verify error', err);
      }
      
    },

    
    async fetch_returned_sign() {
      const res = await axios.post(`/native/bankid/returned`, {
        nonce: this.nonce
      });

      if (res.status === 200) {
        this.verified(res.data);
      }
      else {
        this.verify_status = 'ERROR';
      }
    },


    async register_member(form, post_register_url) {
      
      try {
        this.create_status = 'PENDING';

        const res = await axios.post('/member/public', form);

        if (res.status === 201) {

          this.create_status = 'CREATED';

          messageParent(
            JSON.stringify({
              register: true,
              member_id: res.data.member_id,
              token: res.data.token
            })
          );

          const order = res.data.order;
          const member_id = res.data.member_id;

          if (order) {
            switch (order.expected_action) {
              case 'DINTERO_PAYMENT': {
                this.$router.push(`/payment/${order.shop_order_id}/${order.dintero_id}/${order.token}`);
                break;
              }
              case 'STRIPE_PAYMENT': {
                /// send to stripe payment with register as type
                this.$router.push(`/stripe-payment/${member_id}?client_secret=${order.stripe_client_secret}&type=register&token=${order.token}`);
                break;
              }
              case 'NO_PAYMENT': {
                if (post_register_url) {
                  let url = post_register_url;

                  // add http:// if not present

                  if (!url.match(/^https?:\/\//)) {
                    url = 'http://' + url;
                  }
                  
                  messageParent(JSON.stringify({ redirect: url }));
                  
                  if (!isPlugin()) {
                    window.location.href = url;
                  }
                } else {
                  this.successful_create = true;
                }
                break;
              }
              default: {
                /// STRIPE_INVOICE_PAYMENT
                /// INVOICE_PAYMENT
                /// NOT_DETERMINED
                /// UNKNOWN
                this.toastr(this.$t('COMMON.ERROR'), 'Not implemented payment option: ' + order.expected_action);
                break;
              }
            }
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'No order was created');
          }

          
        } else if (res.status === 409) {
          this.create_status = 'ERROR';

          this.toastr('danger', this.$t('COMMON.ERROR'), 'E-mail finns redan registrerad');

          console.error('register_member status 409');
          
        } else {
          messageParent(JSON.stringify({ register: false }));
          
          this.create_status = 'ERROR';

          console.error('register_member status error', res.status, res.data);
        }
      }
      catch (err) {
        this.create_status = 'ERROR';
        console.error('register_member exception', err);
      }
    },

  },
  computed: {
    ...mapState({
      
    })
  }
};
</script>
